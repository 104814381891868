<template>
  <section>
    <div class="bg-1" :class="{reversed: invertLayout}"></div>
    <v-container>
      <v-row >
        <v-col v-if="twoRows === true && invertLayout === true" md="6"></v-col>
        <v-col
          cols="12"
          sm="6"
          :offset-sm="twoRows === true ? 1 : 0"
          :md="twoRows === true ? 5 : 4"
          offset-md="1"
          :offset-lg="invertLayout === true ? 1 : 2"
          :class="{'mb-sm-12': twoRows === true}"
        >
          <div ref="title">
            <h2 :class="gradientClass">{{title}}</h2>
          </div>
        </v-col>
        <v-col v-if="twoRows === true && invertLayout !== true" sm="5" md="5"></v-col>
        <v-col v-if="twoRows === true && invertLayout !== true" sm="6"></v-col>
        <v-col
          cols="12"
          sm="6"
          md="5"
          offset-md="1"
          lg="4"
          :offset-lg="invertLayout === true ? 2 : 1"
          :class="{'order-md-first': invertLayout === true && twoRows !== true}"
        >
          <MarkdownBlock
            ref="text"
            linkify
            tag="div"
            class="pt-sm-2 pt-md-4"
            :inline="false"
            :text="text"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="ctaAction && ctaAction !== 'none'"
        class="mt-5 mt-md-8"
      >
        <v-col
          cols="12"
          sm="6"
          offset-sm="6"
          md="5"
          :offset-md="invertLayout === true ? 7 : 6"
          offset-lg="7"
        >
          <div ref="cta">
            <Cta :colorSchema="colorSchema" :action="ctaAction" :label="ctaLabel"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'
import ScrollAppear from '@/animations/gsap/scroll-appear'
import Cta from '@/molecules/Cta'
import GradientClass from '@/mixins/GradientClass'

export default {
  name: 'titleAndText',
  mixins: [GradientClass],
  props: {
    invertLayout: {
      type: Boolean,
      default: false
    },
    twoRows: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    ctaAction: {
      type: String,
      required: false
    },
    ctaLabel: {
      type: String,
      required: false
    }
  },
  components: {
    MarkdownBlock,
    Cta
  },
  mounted () {
    this.$nextTick(() => {
      this.tls = []
      this.tls.push(ScrollAppear(this.$refs.title))
      this.tls.push(ScrollAppear(this.$refs.text.$el))
      this.$refs.cta && this.tls.push(ScrollAppear(this.$refs.cta))
    })
  },
  beforeDestroy () {
    this.tls.forEach(tl => tl.kill())
  }
}
</script>
<style lang="scss" scoped>
.bg-1 {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: rem(500px);
  height: rem(500px);
  background: $bg-emo-gradient-3;

  transform: translate(-50%, 20%);
  pointer-events: none;

  &.reversed {
    left: auto;
    right: 0%;
    transform: translate(50%, 20%);

  }
}
</style>
